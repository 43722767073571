.wrapper {
    & h4 {
        padding: 20px 0 40px 0;
    }
}

.form {
    max-width: 450px;

    & .block {
        display: flex;
        justify-content: space-between;

        & label {
            margin-right: 10px;
            flex: 3;
        }
        & input {
            flex: 9;
        }
    }

    & .buttons {
        display: flex;

        & > button {
            margin: 0 5px;
        }
    }

    .color {
        width: 10px;
    }
}
