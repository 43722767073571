.wrapper {
    width: 100%;

    & button {
        width: 100%;
    }

    & input {
        display: inline-block;
        margin-right: 5px;
    }
}
