.wrapper {
    height: 100%;
    width: 100%;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .account {
            & > div {
                display: inline-block;
            }

            button {
                margin-left: 10px;
            }
        }
    }

    h3 {
        padding: 20px 0 40px 0;
    }

    ul {
        display: flex;
        flex-direction: column;

        & > a {
            border: none !important;
        }
    }
}
