.wrapper {
    & > a {
        margin: 0 20px;
    }

    & ul {
        display: flex;
        flex-direction: column;
    }

    & .item {
        display: flex;
        padding: 0 5px;

        & > div {
            margin: 0 10px;
        }

        &__color {
            width: 10px;
            height: 10px;
        }
    }
}
