.menu {
    position: absolute;
    right: 0;
    top: 6%;
    background-color: wheat;
    border-radius: 5px;
    z-index: 5;
    padding: 10px;

    select {
        display: inline-block;
    }
}
