.form {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 5px 10px;
    background-color: wheat;
    border-radius: 5px;
    width: 500px;
    max-width: 100%;
    box-sizing: border-box;

    @media screen and (max-height: 900px) {
        max-height: 300px;
        overflow: scroll;
    }

    & .block {
        display: flex;
        justify-content: space-between;

        & > label {
            margin-right: 10px;
            flex: 3;
        }
        & > input {
            flex: 9;
        }
    }

    & .buttons {
        display: flex;

        & > button {
            margin: 0 5px;
        }
    }
}
