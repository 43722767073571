.dialog {
    position: absolute;
    background-color: gray;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    left: 0;
    top: 0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    & .buttons {
        & button:first-child {
            margin-right: 5px;
        }
    }
}
