.wrapper {
    .areas {
        margin: 40px 0 20px 20px;
        display: flex;
        align-items: center;
        margin-left: 10px;

        & * {
            width: auto;
        }
    }

    a {
        margin: 0 20px;
    }

    ul {
        width: 300px;

        li {
            cursor: pointer;
        }
    }
}
