.wrapper {
    width: 100%;
    height: 100vh;
    position: relative;

    form {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 300px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        border: solid #dee2e6;
        padding: 1.5rem;
        margin-right: 0;
        margin-left: 0;
        border-width: 1px;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;

        & button {
            width: 100%;
        }
    }
}
