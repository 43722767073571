.wrapper {
    position: absolute !important;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    animation: fadeIn 0.2s linear forwards;
    z-index: 11;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    width: 200px;
    height: 50px;

    &_success {
        background-color: #198754;
        border-color: #198754;
        color: #fff;
    }

    &_error {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fadeOutAnimation {
    animation: fadeOut 0.2s linear forwards;
}
